<template>
  <div class="view">
    <div>
      <el-input
          v-model="params.user_id"
          placeholder="用户UID"
          class="mr20 wh200"
      ></el-input>
       <el-date-picker
        v-model="params.created_at_start"
        format="YYYY 年 MM 月 DD 日"
        type="date"
        placeholder="开始时间"
        class="mr20"
      >
      </el-date-picker>
      <el-date-picker
        format="YYYY 年 MM 月 DD 日"
        v-model="params.created_at_end"
        type="date"
        placeholder="结束时间"
        class="mr20"
      >
      </el-date-picker>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
    </div>
    <el-table :data="data.list" v-loading="loading">
      <el-table-column prop="id" label="ID" width="120px"></el-table-column>
      <el-table-column prop="user_id" label="用户UID"></el-table-column>
      <el-table-column prop="name" label="用户名称">
        <template #default="{row}">
          {{ row.user ? row.user.name : '-/-' }}
        </template>
      </el-table-column>
      <el-table-column prop="order_no" label="订单号"> </el-table-column>
      <el-table-column prop="price" label="充值金额"></el-table-column>
      <el-table-column prop="share_amount" label="分成金额"></el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
    </el-table>
    <el-pagination
      class="mt20"
      background
      layout="prev, pager, next"
      :current-page="data.meta.current_page || 1"
      :total="data.meta.total || 15"
      :page-size="data.meta.per_page || 15"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>
<script lang="ts">
import {defineComponent, reactive, ref, onMounted} from 'vue';
import http from '@/http';
export default defineComponent({
  setup() {
    let loading = ref(false);
    let data = reactive({
      list: [],
      meta: {},
    });
    let params = reactive({
      page: 1,
      user_id: undefined,
      created_at_start: undefined,
      created_at_end: undefined,
    });
    // user_id: localStorage.getItem('user_id') || '0',
    const getData = () => {
      loading.value = true;
      http.get('recharge', {params}).then((res) => {
        loading.value = false;
        data.list = res['data'];
        data.meta = res['meta'];
      });
    };
    getData();
    const handlePageChange = (page: number) => {
      params.page = page;
      getData();
    };
    const handleSearch = () => {
      params.page = 1;
      getData();
    };
    onMounted(() => {});
    return {
      loading,
      data,
      params,
      handlePageChange,
      handleSearch,
    };
  },
});
</script>
