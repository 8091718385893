"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vuex_1 = require("vuex");
var app_1 = require("./app");
exports.default = vuex_1.createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app: app_1.app
    }
});
