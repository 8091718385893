"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Cookies = require("js-cookie");
var axios_1 = require("axios");
var config_1 = require("@/config");
var element_plus_1 = require("element-plus");
var store_1 = require("@/store");
var router_1 = require("@/router");
var http = axios_1.default.create({
    baseURL: config_1.server,
    timeout: 3000
});
http.interceptors.request.use(function (config) {
    var token = Cookies.get('access_token');
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
}, function (error) {
    return Promise.reject(error.response);
});
http.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    if (error.response) {
        var err = error.response;
        switch (err.status) {
            case 500:
                break;
            case 401:
                // 添加队列
                if (err.data === 'Client authentication failed') {
                    element_plus_1.ElMessage.error('用户名或密码错误');
                }
                else {
                    store_1.default.commit('logout');
                    router_1.default.replace({
                        path: '/login',
                    });
                    element_plus_1.ElMessage.error('请重新登录');
                }
                break;
            case 404:
                break;
            default:
                if (err.data.error) {
                    // 报错了
                }
                else if (err.data.errors) {
                    var errorMessages = Object.values(err.data.errors);
                    errorMessages.forEach(function (item) {
                        item.forEach(function (subItem) {
                            element_plus_1.ElMessage.error(subItem);
                        });
                    });
                }
                else {
                    element_plus_1.ElMessage.error(err.data.message);
                }
                break;
        }
        return Promise.reject(err);
    }
    else {
        return Promise.reject(error);
    }
});
exports.default = http;
