"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("vue-router");
var Home_vue_1 = require("../views/Home.vue");
// import { useStore } from 'vuex';
var element_plus_1 = require("element-plus");
var Cookies = require("js-cookie");
var store_1 = require("../store");
var http_1 = require("@/http");
var routes = [
    {
        path: '/',
        name: 'Home',
        component: Home_vue_1.default,
        children: [
            {
                path: '/index',
                name: 'index',
                component: function () { return Promise.resolve().then(function () { return require('../views/Index.vue'); }); },
                meta: {
                    isAuth: true
                },
            },
            {
                path: '/promotion',
                name: 'Promotion',
                component: function () { return Promise.resolve().then(function () { return require('../views/Promotion.vue'); }); },
                meta: {
                    isAuth: true
                },
            },
            {
                path: '/users',
                name: 'PromotionUser',
                component: function () { return Promise.resolve().then(function () { return require('../views/PromotionUser.vue'); }); },
                meta: {
                    isAuth: true
                },
            },
            {
                path: '/withdrawal',
                name: 'Withdrawal',
                component: function () { return Promise.resolve().then(function () { return require('../views/Withdrawal.vue'); }); },
                meta: {
                    isAuth: true
                },
            },
            {
                path: '/get-money',
                name: 'GetMoney',
                component: function () { return Promise.resolve().then(function () { return require('../views/GetMoney.vue'); }); },
                meta: {
                    isAuth: true
                },
            },
            {
                path: '/user-info',
                name: 'UserInfo',
                component: function () { return Promise.resolve().then(function () { return require('../views/UserInfo.vue'); }); },
                meta: {
                    isAuth: true
                },
            }
        ],
        redirect: '/index'
    },
    {
        path: '/login',
        name: 'Login',
        component: function () { return Promise.resolve().then(function () { return require('../views/Login.vue'); }); }
    }
];
var router = vue_router_1.createRouter({
    history: vue_router_1.createWebHistory(process.env.BASE_URL),
    routes: routes
});
router.beforeEach(function (to, form, next) {
    if (to.name === 'Login' && to.query.anchorId) {
        var params = {
            code: to.query.anchorId
        };
        http_1.default.get('anchor_code', { params: params }).then(function (res) {
            Cookies.set('access_token', res.data.token, 720000);
            element_plus_1.ElMessage.success('登陆成功');
            store_1.default.dispatch('getUserInfo');
            router.push({ path: '/' });
        });
    }
    if (to.meta.isAuth) {
        if (Cookies.get('access_token')) {
            next();
        }
        else {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
            element_plus_1.ElMessage.error('登录已失效，请重新登录');
        }
    }
    else {
        next();
    }
});
router.afterEach(function (to) {
    store_1.default.commit('SET_ACTIVE_INDEX', to.path);
});
exports.default = router;
