<template>
  <div style="flex:1" >
    <div class="index">
      <div class="header-box cor-red weight600">
        请认真填写正确的 姓名、支付宝账号和二维码
        ，如填写错误造成损失，网站概不负责！
      </div>
      <div class="header-box weight600 mt20">
        提示： 填写格式为 **平台（支付宝/微信）姓名 账号 收款码
      </div>
    </div>
    <div class="view">
      <div id="w_view"></div>
      <el-button @click="submit" class="mt20" type="primary">提交</el-button>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue';
// import {server} from '@/config';
import http from '@/http';
import WE from 'wangeditor';
import {mapActions, mapState} from 'vuex';
export default defineComponent({
  name: 'userInfo',
  data() {
    return {
      // 编辑器实例
      editor: null,
      pay_info: undefined,
      // 富文本菜单选项配置
      menuItem: [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'justify',
        'image',
        'video',
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => {
        return state.app.userInfo;
      },
    }),
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.$nextTick(() => {
          this.editor.txt.html(val.pay_info);
        });
      }
    },
  },
  methods: {
    ...mapActions(['getUserInfo']),

    initEditor() {
      // 获取编辑器dom节点
      const editor = new WE('#w_view');
      // 配置编辑器
      editor.config.showLinkImg = false; /* 隐藏插入网络图片的功能 */
      editor.config.onchangeTimeout = 400; /* 配置触发 onchange 的时间频率，默认为 200ms */
      editor.config.uploadImgMaxLength = 1; /* 限制一次最多能传几张图片 */
      editor.config.zIndex = 1000; /* 限制一次最多能传几张图片 */
      // editor.config.showFullScreen = false; /* 配置全屏功能按钮是否展示 */
      editor.config.menus = [...this.menuItem]; /* 自定义系统菜单 */
      // editor.config.uploadImgMaxSize = 5 * 1024 * 1024 /* 限制图片大小 */;
      editor.config.customAlert = (err) => {
        Message.error(err);
      };
      // 监控变化，同步更新数据
      editor.config.onchange = (newHtml) => {
        // 异步更新组件富文本值的变化
        this.pay_info = newHtml;
      };
      // 自定义上传图片
      editor.config.customUploadImg = (resultFiles, insertImgFn) => {
        var forms = new FormData();
        let config = {
          headers: {'Content-Type': 'multipart/form-data'},
        };
        forms.append('file', resultFiles[0], resultFiles[0].name);
        http.post('file', forms, config).then((res) => {
          res && insertImgFn(res.path);
        });
      };
      // 创建编辑器
      editor.create();
      this.editor = editor;
    },
    submit() {
      this.$confirm('确定保存内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          http.patch('user', {pay_info: this.pay_info}).then((res) => {
            this.$message.success('提交成功');
            // this.pay_info = undefined;
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getUserInfo();
    this.initEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
    this.editor = null;
  },
});
</script>
<style lang="less"></style>
