<template>
  <div class="layout-sider">
    <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" router>
      <el-menu-item index="/index">
        <i class="el-icon-s-platform"></i>
        <span slot="title">主页</span>
      </el-menu-item>
      <el-menu-item index="/promotion">
        <i class="el-icon-s-order"></i>
        <span slot="title">我的订单</span>
      </el-menu-item>
      <el-menu-item index="/users">
        <i class="el-icon-s-promotion"></i>
        <span slot="title">推广用户</span>
      </el-menu-item>
      <el-menu-item index="/withdrawal">
        <i class="el-icon-s-claim"></i>
        <span slot="title">提现记录</span>
      </el-menu-item>
<!--      <el-menu-item index="/user-info">
        <i class="el-icon-user"></i>
        <span slot="title">个人信息</span>
      </el-menu-item>-->
      <el-menu-item index="/get-money">
        <i class="el-icon-s-finance"></i>
        <span slot="title">申请工资</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
import {defineComponent} from 'vue';
import {mapState} from 'vuex';
export default defineComponent({
  name: 'Home',
  computed: {
    ...mapState({
      activeIndex: (state) => state.app.activeIndex,
    }),
  },
});
</script>
