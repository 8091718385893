<template>
  <div class="index" v-loading="fullscreenLoading">
    <header>
      <div class="header-box cor-red weight600">提现申请于每月1号结算！</div>
      <div class="header-box mt20">
        我的推广链接:
        <a
          class="promotion-url"
          :href="
            `https://www.t1cs2.top/?code=${
              userInfo ? userInfo.code : 'undefined'
            }`
          "
          target="_blank"
          >https://www.t1cs2.top/?code={{
            userInfo ? userInfo.code : 'undefined'
          }}</a
        >
      </div>
    </header>
    <ul class="contents mt10" v-if="data && data.indexData">
      <li class="content-wrap">
        <div class="content">
          <p>今日分成收入</p>
          <p class="mt10">{{ data.indexData.today_share_amount }}</p>
        </div>
      </li>
<!--      <li class="content-wrap">
        <div class="content">
          <p>累计分成收入</p>
          <p class="mt10">{{ data.indexData.total_share_amount }}</p>
        </div>
      </li>-->
      <li class="content-wrap">
        <div class="content">
          <p>账户余额</p>
          <p class="mt10">{{ userInfo ? userInfo.commission : '0.00' }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>今日流水</p>
          <p class="mt10">{{ data.indexData.today_recharge }}</p>
        </div>
      </li>
<!--      <li class="content-wrap">
        <div class="content">
          <p>累计流水</p>
          <p class="mt10">{{ data.indexData.total_recharge }}</p>
        </div>
      </li>-->
      <li class="content-wrap">
        <div class="content">
          <p>今日客单价</p>
          <p class="mt10">{{ data.indexData.customer_price }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>昨日流水</p>
          <p class="mt10">{{ data.indexData.yesterday_recharge }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>用户充值人数</p>
          <p class="mt10">{{ data.indexData.recharge_number }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>下级用户数</p>
          <p class="mt10">{{ data.indexData.user_count }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>当月下级充值流水</p>
          <p class="mt10">{{ data.indexData.current_month_recharge }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>当月注册新用户</p>
          <p class="mt10">{{ data.indexData.current_month_reg_user_count }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>上月下级充值</p>
          <p class="mt10">{{ data.indexData.last_month_recharge }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>上月新注册充值用户</p>
          <p class="mt10">{{ data.indexData.last_month_reg_user_count }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>上月新注册用户</p>
          <p class="mt10">{{ data.indexData.last_month_reg_user_count_recharge }}</p>
        </div>
      </li>
      <li class="content-wrap">
        <div class="content">
          <p>本月新注册充值用户</p>
          <p class="mt10">{{ data.indexData.current_month_reg_user_count_recharge }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import {defineComponent, reactive, computed, ref} from 'vue';
import http from '@/http';
import {useStore} from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    store.dispatch('getUserInfo');
    const userInfo = computed(() => {
      return store.state.app.userInfo;
    });
    const data = reactive({
      indexData: {},
    });
    let fullscreenLoading = ref(false);

    const getData = () => {
      fullscreenLoading.value = true;
      http
        .get('home')
        .then((res) => {
          data.indexData = res.data;
        })
        .finally(() => {
          fullscreenLoading.value = false;
        });
    };
    getData();
    const amountFilter = (amount) => {
      return Number(amount).toFixed(2);
    };
    return {data, amountFilter, userInfo, fullscreenLoading};
  },
});
</script>
