"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var Silder_vue_1 = require("@/components/layout/Silder.vue");
var Header_vue_1 = require("@/components/layout/Header.vue");
// import { server } from '@/config'
exports.default = vue_1.defineComponent({
    name: 'Home',
    components: {
        Silder: Silder_vue_1.default,
        Header: Header_vue_1.default,
    },
    mounted: function () { },
});
