<template>
  <div class="home">
    <Header></Header>
    <div class="content-body">
      <Silder></Silder>
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Silder from '@/components/layout/Silder.vue';
import Header from '@/components/layout/Header.vue';
// import { server } from '@/config'

export default defineComponent({
  name: 'Home',
  components: {
    Silder,
    Header,
  },
  mounted() {},
});
</script>
<style lang="less">
.home {
  background: #f5f5f5;
  min-height: calc(100% - 60px);
}
</style>
