<template>
  <div class="view">
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="当前可提取" prop="price2">
        <el-input v-model="form.commission" disabled></el-input>
      </el-form-item>
      <el-form-item label="冻结金额" prop="price2">
        <el-input v-model="form.freeze_commission" disabled></el-input>
      </el-form-item>
      <el-form-item label="发放UID" prop="remark">
        <el-input v-model="form.remark" placeholder="填写需要发放的用户UID"></el-input>
      </el-form-item>
      <el-form-item label="提取商城币" prop="price">
        <el-input v-model="form.price"></el-input>
      </el-form-item>
    </el-form>
    <el-button @click="submit" style="margin-left:100px" type="primary"
      >提交申请</el-button>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex';
import {defineComponent} from 'vue';
import http from '@/http';
export default defineComponent({
  data() {
    return {
      form: {
        remark: undefined,
        price: 0,
        commission: 'NA',
        freeze_commission: 'NA',
      },
      rules: {
        price: [
          {required: true, message: '提取金额 不能为空。', trigger: 'blur'},
        ],
        remark: [
          {required: true, message: '发放UID 不能为空。', trigger: 'blur'},
        ],
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
    }),
  },
  watch: {
    userInfo(val) {
      if (val) {
        const {commission, freeze_commission} = val;
        Object.assign(this.form, {commission, freeze_commission});
      }
    },
  },
  methods: {
    ...mapActions(['getUserInfo']),
    submit() {
      this.$confirm('确定要申请提取工资?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          http.post('withdraw', {price: this.form.price}).then((res) => {
            this.$message.success(res.data);
            this.form.price = 0;
            this.getUserInfo();
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getUserInfo();
  },
});
</script>
<style lang="less"></style>
