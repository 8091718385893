<template>
  <div class="view">
    <el-table :data="data.list" v-loading="loading">
      <el-table-column prop="id" label="用户UID"></el-table-column>
      <el-table-column prop="name" label="用户昵称"></el-table-column>
      <el-table-column prop="today_recharge" label="今日充值">
      </el-table-column>
      <el-table-column
        prop="recharge_amount"
        label="累计充值"
      ></el-table-column>
      <el-table-column prop="total_recharge" label="累计分成"></el-table-column>
    </el-table>
    <el-pagination
      class="mt20"
      background
      layout="prev, pager, next"
      :current-page="data.meta.current_page || 1"
      :total="data.meta.total || 15"
      :page-size="data.meta.per_page || 15"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>
<script lang="ts">
import {defineComponent, reactive, ref, onMounted} from 'vue';
import http from '@/http';
export default defineComponent({
  setup() {
    let loading = ref(false);
    let data = reactive({
      list: [],
      meta: {},
    });
    let params = reactive({
      page: 1,
    });
      // user_id: localStorage.getItem('user_id') || '0',
    const getData = () => {
      loading.value = true;
      http.get('users', {params}).then((res) => {
        loading.value = false;
        data.list = res['data'];
        data.meta = res['meta'];
      });
    };
    getData();
    const handlePageChange = (page: number) => {
      params.page = page;
      getData();
    };
    onMounted(() => {});
    return {
      loading,
      data,
      params,
      handlePageChange,
    };
  },
});
</script>
