<template>
  <div class="login">
    <p class="login-banner"></p>
    <div class="login-box with-shadow">
      <section class="login-box-top">
        <h5 style="text-align:center">渠道后台管理系统</h5>
      </section>
      <section class="login-form">
        <el-form :model="loginForm" :rules="rules" ref="loginForm">
          <el-form-item prop="username">
            <el-input
              prefix-icon="el-icon-fa-user"
              size="large"
              type="text"
              v-model="loginForm.username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              prefix-icon="el-icon-fa-lock"
              size="large"
              type="password"
              v-model="loginForm.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              style="width: 100%"
              type="primary"
              :loading="submitButtonLoading"
              @click="handleLogin"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {clientSecret} from '@/config';
import http from '../http.ts';

// const userInfo = {
//   username: '594708461@qq.com',
//   password: 'zzb3908'
// }
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '用户名不能为空',
          },
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
          },
        ],
      },
      submitButtonLoading: false,
    };
  },
  methods: {
    ...mapActions(['getUserInfo']),
    handleLogin() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.submitButtonLoading = true;
          http
            .post(
              'login',
              {
                grant_type: 'password',
                client_id: 2,
                client_secret: clientSecret,
                username: this.loginForm.username,
                password: this.loginForm.password,
                scope: '',
              },
              {
                headers: {
                  Authorization: 'Bearer ' + clientSecret,
                },
              }
            )
            .then((res) => {
              this.$store.commit('SET_LOGIN_TOKEN', res);
              this.$message.success('登陆成功');
              this.getUserInfo();
              this.$router.push({path: '/'});
            })
            .finally(() => {
              this.submitButtonLoading = false;
            });
        }
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.login-banner {
  position: absolute;
  width: 100%;
  height: 849px;
  margin: auto;
  top: -400px;
  left: 0;
  right: 0;
  background-image: url('../assets/img/background-login.png');
  background-size: 100% 100%;
}
.login-box {
  width: 420px;
  height: 360px;
  position: relative;
  left: 360px;
  top: 180px;
  background: #fff;
  padding-top: 60px;
}
.login-logo {
  width: 120px;
  height: 120px;
  display: block;
  color: #0079fe;
  margin: 20px auto;
}
.login-box-top {
  h5 {
    font-size: 26px;
    font-weight: 700;
    color: #0079fe;
    line-height: 56px;
  }
}
.login-form {
  padding: 40px 80px;
}
</style>
