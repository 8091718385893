"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var http_1 = require("@/http");
exports.default = vue_1.defineComponent({
    setup: function () {
        var loading = vue_1.ref(false);
        var data = vue_1.reactive({
            list: [],
            meta: {},
        });
        var params = vue_1.reactive({
            page: 1,
        });
        // user_id: localStorage.getItem('user_id') || '0',
        var getData = function () {
            loading.value = true;
            http_1.default.get('users', { params: params }).then(function (res) {
                loading.value = false;
                data.list = res['data'];
                data.meta = res['meta'];
            });
        };
        getData();
        var handlePageChange = function (page) {
            params.page = page;
            getData();
        };
        vue_1.onMounted(function () { });
        return {
            loading: loading,
            data: data,
            params: params,
            handlePageChange: handlePageChange,
        };
    },
});
