<template>
  <div class="layout-header">
    <div class="logo">
      渠道后台管理系统
    </div>
    <div class="logout">
      <el-avatar
        class="mr20"
        v-if="userInfo"
        :src="userInfo.avatar"
      ></el-avatar>
      <el-button @click="handleLogout()">退出</el-button>
    </div>
  </div>
</template>
<script>
import {ElMessage} from 'element-plus';
import {defineComponent, computed} from 'vue';
import {useStore} from 'vuex';
import router from '@/router';
export default defineComponent({
  setup() {
    const store = useStore();
    store.dispatch('getUserInfo');
    const userInfo = computed(() => {
      return store.state.app.userInfo;
    });
    const handleLogout = () => {
      router.push('/login');
      store.commit('logout');
      ElMessage.success('退出登录成功');
    };
    return {userInfo, handleLogout};
  },
});
</script>
