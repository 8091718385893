"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var element_plus_1 = require("element-plus");
var App_vue_1 = require("./App.vue");
require("element-plus/lib/theme-chalk/index.css");
var router_1 = require("./router");
var store_1 = require("./store");
require("./assets/style/base.less");
var zh_cn_1 = require("element-plus/lib/locale/lang/zh-cn");
require("dayjs/locale/zh-cn");
vue_1.createApp(App_vue_1.default).use(store_1.default).use(router_1.default).use(element_plus_1.default, { locale: zh_cn_1.default, size: 'small' }).mount('#app');
